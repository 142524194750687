const API = {

  // AUTH
  register       : 'api/sellit/register',
  forgot         : 'api/forgot',
  recoverCode    : 'api/recover-code',
  resetPassword  : 'api/reset-password',
  login          : 'api/login',
  logout         : 'api/logout',
  accountInfo    : 'api/account-info',
  accountUpdate  : 'api/account-info/update',
  checkAuth      : 'api/check-auth',
  company        : 'api/company',
  activation     : 'api/activation',
  reActivation   : 'api/re-activation',
//   users          : 'api/users',
  userBase       : 'api/user-base',
  userExists     : 'api/user-exists',
  switchAccount  : 'api/switch-account',

  profile : {
    profile           : 'api/profile',
    update            : 'api/profile/update',
    password          : 'api/profile/password',
    theme             : 'api/profile/theme',
  },

  history: {
    search         : "api/history/search",
  },

  // CRS
  crs: {
    countries      : 'api/crs/countries',
    regions        : 'api/crs/regions',
    regionsSearch  : 'api/crs/regions/search',
    cities         : 'api/crs/cities',
    citiesSearch   : 'api/crs/cities/search',
    regionGroups   : 'api/crs/region-groups',
    tocs           : 'api/crs/tocs',
    tocGroups      : 'api/crs/tocs/groups',
    entryPoints    : 'api/crs/entry-points',
    entryPointsSearch : 'api/crs/entry-points/search',
    subtypes       : 'api/crs/subtypes',
    roomTypes      : 'api/crs/room-types',
    roomTypesSearch: 'api/crs/room-types/search',
    serviceTypesSearch: 'api/crs/service-types/search',
    roomLocationSearch: 'api/crs/room-location/search',
    roomFacilitySearch: 'api/crs/room-facility/search',
    giatasSearch   : 'api/crs/giatas/search',
  },

  partners: {
    all         : "api/partners/all",
    find        : "api/partners/find",
    search      : "api/partners/search",
    update      : "api/partners/update",
    create      : "api/partners/create",
    delete      : "api/partners/delete",
  },

  suppliers: {
    all         : "api/suppliers/all",
    find        : "api/suppliers/find",
    search      : "api/suppliers/search",
    update      : "api/suppliers/update",
    create      : "api/suppliers/create",
    delete      : "api/suppliers/delete",
  },

  announce: {
    all         : "api/announce/all",
    find        : "api/announce/find",
    search      : "api/announce/search",
    update      : "api/announce/update",
    create      : "api/announce/create",
    delete      : "api/announce/delete",
  },

  cancelPolices: {
    all         : "api/cancel-polices/all",
    find        : "api/cancel-polices/find",
    search      : "api/cancel-polices/search",
    update      : "api/cancel-polices/update",
    create      : "api/cancel-polices/create",
    delete      : "api/cancel-polices/delete",
  },

  users: {
    all         : "api/users/all",
    find        : "api/users/find",
    search      : "api/users/search",
    update      : "api/users/update",
    create      : "api/users/create",
    delete      : "api/users/delete",
  },

  content: {
    trips: {
      find       : "api/content/trips/find",
      search     : "api/content/trips/search",
      update     : "api/content/trips/update",
      create     : "api/content/trips/create",
      delete     : "api/content/trips/delete",
      download   : "api/content/trips/download",
      periods: {
        find     : "api/content/trips/periods/find",
        search   : "api/content/trips/periods/search",
        bulkAction   : "api/content/trips/periods/bulk-action",
        update   : "api/content/trips/periods/update",
        create   : "api/content/trips/periods/create",
        delete   : "api/content/trips/periods/delete",
        cancel   : "api/content/trips/periods/cancel",
      }
    },
    entryPoints: {
      find     : "api/content/entry-points/find",
      all      : "api/content/entry-points/all",
      search   : "api/content/entry-points/search",
      update   : "api/content/entry-points/update",
      create   : "api/content/entry-points/create",
      delete   : "api/content/entry-points/delete",
    },
    transport: {
      find     : "api/content/transport/find",
      all      : "api/content/transport/all",
      search   : "api/content/transport/search",
      update   : "api/content/transport/update",
      create   : "api/content/transport/create",
      delete   : "api/content/transport/delete",
    },
    tripRooms: {
      find     : "api/content/trip-rooms/find",
      all      : "api/content/trip-rooms/all",
      search   : "api/content/trip-rooms/search",
      update   : "api/content/trip-rooms/update",
      create   : "api/content/trip-rooms/create",
      delete   : "api/content/trip-rooms/delete",
    },
    tripAddons: {
      find     : "api/content/trip-addons/find",
      all      : "api/content/trip-addons/all",
      search   : "api/content/trip-addons/search",
      update   : "api/content/trip-addons/update",
      create   : "api/content/trip-addons/create",
      delete   : "api/content/trip-addons/delete",
    },
    discounts: {
      find     : "api/content/discounts/find",
      all      : "api/content/discounts/all",
      search   : "api/content/discounts/search",
      update   : "api/content/discounts/update",
      create   : "api/content/discounts/create",
      delete   : "api/content/discounts/delete",
    },
  },

  bookings: {
    find     : "api/bookings/find",
    findByPrc: "api/bookings/find-by-prc",
    all      : "api/bookings/all",
    search   : "api/bookings/search",
    report   : "api/bookings/report",
    update   : "api/bookings/update",
    create   : "api/bookings/create",
    delete   : "api/bookings/delete",
    toma: {
      find     : "api/bookings/toma/find",
      findByPrc: "api/bookings/toma/find-by-prc",
      action   : "api/bookings/toma/action",
      update   : "api/bookings/toma/update",
    },
    mails: {
      find     : "api/bookings/mails/find",
      all      : "api/bookings/mails/all",
      search   : "api/bookings/mails/search",
      update   : "api/bookings/mails/update",
      create   : "api/bookings/mails/create",
      send     : "api/bookings/mails/send",
      delete   : "api/bookings/mails/delete",
    },
  },
}

export default API
